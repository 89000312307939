import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyDf8X6AwX-raxfbvtazvBBZcw7OgV71_ug",
    authDomain: "math-util.firebaseapp.com",
    databaseURL: "https://math-util.firebaseio.com",
    projectId: "math-util",
    storageBucket: "math-util.appspot.com",
    messagingSenderId: "964881320238",
    appId: "1:964881320238:web:a7c654f9dae5c363aed2bf",
    measurementId: "G-93ZQRYG6N1"
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
