import React from "react";
import { Redirect, Route } from "react-router-dom";
import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { AppPage } from "./declarations";

import Menu from "./components/Menu";
import Home from "./pages/home/Home";
import GridPaper from "./pages/grid-paper/GridPaper";
import { home, grid } from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import "./Main.css";

const appPages: AppPage[] = [
  {
    title: "Home",
    url: "/app/home",
    icon: home,
    showInMenu: true
  },
  {
    title: "Grid Paper Generator",
    url: "/app/gridpaper",
    icon: grid,
    showInMenu: true
  }
];

const App: React.FC = () => (
  <IonReactRouter>
    <IonSplitPane contentId="main">
      <Menu appPages={appPages.filter(value => value.showInMenu)} />
      <IonRouterOutlet id="main">
        <Route path="/app/home" component={Home} exact={true} />
        <Route path="/app/gridpaper" component={GridPaper} exact={true} />
        <Route path="/app" render={() => <Redirect to="/app/home" />} exact={true} />
      </IonRouterOutlet>
    </IonSplitPane>
  </IonReactRouter >
);

export default App;
