// import {
//   IonIcon,
//   IonItem,
//   IonList
// } from "@ionic/react";
// import {
//   americanFootball,
//   basketball,
//   beer,
//   bluetooth,
//   boat,
//   build,
//   flask,
//   football,
//   paperPlane,
//   wifi
// } from "ionicons/icons";
import React, { useEffect } from "react";
import "./PrintComponent.css";

declare const Desmos: any;

// TODO: Update to using class-based component.

const PrintComponent: React.FC = () => {

  var calculator: any;

  useEffect(() => {
    let elt = document.getElementById('desmos');
    console.log(elt);
    if (elt) {
      if (!calculator) {
        // setTimeout(() => {
        if (elt) {
          if (!calculator) {
            calculator = Desmos.GraphingCalculator(elt);
            calculator.setExpression({ id: 'graph1', latex: 'y=x^2' });
          }
        }
        // }, 250);
      }
    }
  }, []);

  const setHeight = () => {
    const element = document.getElementById('desmos');
    if (element) {
      element.style.height = element.offsetWidth + 'px';
    }
    return null;
  }

  return (
    // <ListItems />
    <div style={{ width: '100%', paddingTop: '100%', position: 'relative' }} id="desmos-container">
      <div id="desmos" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}></div>
    </div>
  );
};

// const ListItems: React.FC = () => {
//   const icons = [
//     flask,
//     wifi,
//     beer,
//     football,
//     basketball,
//     paperPlane,
//     americanFootball,
//     boat,
//     bluetooth,
//     build
//   ];

//   const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(x => {
//     return (
//       <IonItem key={x}>
//         <IonIcon icon={icons[x - 1]} slot="start" />
//         Item {x}
//         <div className="item-note" slot="end">
//           This is item # {x}
//         </div>
//       </IonItem>
//     );
//   });

//   return <IonList>{items}</IonList>;
// };

export default PrintComponent;
