import React from "react";
import { Route, Redirect } from "react-router-dom";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import PrintComponent from "./pages/grid-paper/PrintComponent";
import Main from "./Main";

const App: React.FC = () => (
  <IonApp style={{ maxWidth: "1200px", margin: "auto" }}>
    <ErrorPage></ErrorPage>
    {/* Global routing for the app */}
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/print" component={PrintComponent} exact={true} />
        <Route path="/app" component={Main} />
        <Route
          path="/"
          render={() => <Redirect to="/app/home" />}
          exact={true}
        />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

const ErrorPage: React.FC = () => (
  <div
    style={{
      width: "100%",
      height: "100%",
      textAlign: "center",
      backgroundColor: "white"
    }}
  >
    404 Not Found
  </div>
);

export default App;
